//Regex
export const EMAIL_REGEX = /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
export const US_PHONE_REGEX = /^\(\d{3}\) \d{3}-\d{4}/;
export const EMAIL_AND_PHONE_COMBINED_REGEX =
  /^([a-zA-Z0-9_\.\-\+])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|^\(\d{3}\) \d{3}-\d{4}/; //does not allow 	Б	В	Г
export const ASCII_CHARS_REGEX = /^[\x00-\x7F]*$/;
export const NUMBERS_REGEX = /^[0-9]*$/;
export const DIGITS_ONLY_REGEX = /\$\d+(?![0-9.])/g;
export const EMPTY_SPACES_REGEX = /^(?!\s+$)/;
export const PASSWORD_REGEX =
  /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ])[A-Za-z0-9^$*.[\]{}()?"!@#%&/\\,><':;|_~`=+\- ]{8,256}$/;
export const DATE_REGEX = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/i; // matches mm/dd/yyyy

//Urls
export const LOGIN_PAGE_URL = '/auth0';
export const RESET_PASSWORD_PAGE_URL = '/auth/resetPassword';
export const SIGN_UP_PAGE_URL = '/auth/signUp';
export const CANDIDATE_CREATE_PAGE_URL = '/community-health-worker';
export const THANK_YOU_PAGE_URL = '/thankYou';
export const HIRING_SECTION_URL = '/hiring';
export const ASK_MARY_SECTION_URL = '/ask-mary';
export const ENROLLMENT_PLANNER_SECTION_URL = '/enrollmentPlanner';
export const CLIENTS_SECTION_URL = '/clients';
export const PERFORMANCE_SECTION_URL = '/performance';
export const MY_DAY_SECTION_URL = '/my-day';

//section organizations
//-----
//Those urls are used in links and buttons for onsite navigation
//Update here and in App.tsx file when doing the changes
//-----
export const ORGANIZATIONS_SECTION_URL = '/organizations';
export const ABOUT_PAGE_URL = `${ORGANIZATIONS_SECTION_URL}/about/`;
export const ALL_PROGRAMS_PAGE_URL = `${ORGANIZATIONS_SECTION_URL}/programs/`;
export const NEW_PROGRAM_PAGE_URL = `${ORGANIZATIONS_SECTION_URL}/programs/new-program/`;
export const ALL_USERS_PAGE_URL = `${ORGANIZATIONS_SECTION_URL}/users/`;
export const INTEGRATIONS_PAGE_URL = `${ORGANIZATIONS_SECTION_URL}/integrations/`;
export const ACTIVATE_PAGE_URL = `${ORGANIZATIONS_SECTION_URL}/activate/`;
export const SSO_PAGE_URL = `${ORGANIZATIONS_SECTION_URL}/sso/`;

//Placeholders
export const PLACEHOLDER_PHONE = '(123) 456-7812';
export const PLACEHOLDER_EMAIL = 'Enter your email';
export const PLACEHOLDER_PASSWORD = 'Enter your password';
export const PLACEHOLDER_FIRST_NAME = 'John';
export const PLACEHOLDER_LAST_NAME = 'Doe';

//Masks
export const PHONE_INPUT_MASK = [
  '(',
  /[1-9]/,
  /\d/,
  /\d/,
  ')',
  ' ',
  /\d/,
  /\d/,
  /\d/,
  '-',
  /\d/,
  /\d/,
  /\d/,
  /\d/
];

export { default as endpoints } from './endpoints';
