import MaterialStack, { StackProps } from '@mui/material/Stack';

const Stack = ({
  gap,
  component = 'div',
  ...props
}: StackProps & { component?: React.ElementType<any> }) => {
  return (
    <MaterialStack
      {...props}
      component={component}
      sx={{
        ...props.sx,
        '& > * + *': {
          marginTop: props.flexDirection !== 'row' ? gap : '0px',
          marginLeft: props.flexDirection === 'row' ? gap : '0px'
        }
      }}
    />
  );
};

export default Stack;
