// import { axiosPublic } from '../../modules/networkTools';
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios, { AxiosError } from 'axios';
import {
  getClientsFiltersObject,
  setClientsFiltersObject
} from 'modules/networkTools/localStorageTokens';
import { axiosPrivate, axiosPublic, logout } from 'modules/networkTools/networkTools';

import { operations } from '../../api/generated-types';

type Register = operations['register'];
type Logout = operations['logout'];
type RequestChangePasswordCode = operations['requestChangePasswordCode'];
type ChangePassword = operations['changePassword'];

export const postRegisterUser = createAsyncThunk<
  unknown,
  Register['requestBody']['content']['application/json'],
  { rejectValue: Register['responses']['500']['content']['application/json']['errors'] }
>('user/register', async ({ firstName, lastName, email, phone, password }, { rejectWithValue }) => {
  try {
    const response = await axiosPublic.post(`recruiter/registration`, {
      firstName,
      lastName,
      email,
      phone,
      password
    });
    return response.data as Register['responses'][200];
  } catch (error: any | AxiosError) {
    const err = error as AxiosError | Error;
    if (axios.isAxiosError(err)) {
      return rejectWithValue(err?.response?.data.errors);
    }
  }
});

export const postLoginUser = createAsyncThunk(
  'setup/auth/login',
  async ({ username, password }: { username: string; password: string }, { rejectWithValue }) => {
    try {
      const response: any = await axiosPublic.post(`setup/auth/login`, { username, password });

      return response.data;
    } catch (error) {
      const err = error as AxiosError | Error;
      if (axios.isAxiosError(err)) {
        //EXTEND ERR MESSAGE TO
        //HANDLE 429 TOO MANY ATTEMPTS
        const status = err?.response?.status;
        const errObject = err?.response?.data.errors;
        errObject['status'] = status;
        return rejectWithValue(errObject);
      }
    }
  }
);

export const postLogoutUser = createAsyncThunk<
  Logout['responses'][200],
  Logout['requestBody']['content']['application/json']['refreshToken'],
  { rejectValue: Logout['responses']['500']['content']['application/json']['errors'] }
>('setup/auth/logout', async (refreshToken: string, { rejectWithValue }) => {
  try {
    await axiosPrivate.post(`setup/auth/logout`, { refreshToken });
    if (getClientsFiltersObject().length) {
      setClientsFiltersObject(
        getClientsFiltersObject().map((a: any) => ({
          ...a,
          filters: {
            ...a.filters,
            statuses: [...new Set([...a.filters?.statuses, 'PENDING'])]
          }
        }))
      );
    }
    logout();
  } catch (error) {
    const err = error as AxiosError | Error;
    if (axios.isAxiosError(err)) {
      return rejectWithValue(err?.response?.data.errors);
    }
  }
});

export const getForcePasswordResetRequest = createAsyncThunk<
  'email',
  RequestChangePasswordCode['parameters']['query'],
  {
    rejectValue: RequestChangePasswordCode['responses']['500']['content']['application/json']['errors'];
  }
>('user/forcePasswordResetRequest', async ({ email = '' }, { rejectWithValue }) => {
  const params = new URLSearchParams();
  params.append('email', email);
  const request = {
    params: params
  };
  try {
    const response = await axiosPrivate.get(`setup/reset-password/force/request`, request);

    return response as any;
  } catch (error: any | AxiosError) {
    const err = error as AxiosError | Error;
    if (axios.isAxiosError(err)) {
      return rejectWithValue(err?.response?.data.errors);
    }
  }
});

export const getResetPasswordCode = createAsyncThunk<
  'email' | undefined,
  RequestChangePasswordCode['parameters']['query'],
  {
    rejectValue: RequestChangePasswordCode['responses']['500']['content']['application/json']['errors'];
  }
>('user/resetGetCode', async ({ email = '', inviteCode }, { rejectWithValue }) => {
  const params = new URLSearchParams();
  params.append('email', email);
  const request = {
    params: params
  };
  try {
    const response = await axiosPublic.get(`setup/reset-password/request/code`, request);

    return response as any;
  } catch (error: any | AxiosError) {
    const err = error as AxiosError | Error;
    if (axios.isAxiosError(err)) {
      return rejectWithValue(err?.response?.data.errors);
    }
  }
});

export const postChangePasswordUser = createAsyncThunk<
  ChangePassword['responses']['200'],
  ChangePassword['requestBody']['content']['application/json'],
  { rejectValue: ChangePassword['responses']['500']['content']['application/json']['errors'] }
>('user/changePassword', async ({ password, code, email, forceReset }, { rejectWithValue }) => {
  try {
    const response = await axiosPublic.post(
      `setup/reset-password/change/password?forceReset=${forceReset ? 'true' : 'false'}`,
      {
        email,
        code,
        password
      }
    );

    return response.data;
  } catch (error: any | AxiosError) {
    const err = error as AxiosError | Error;
    if (axios.isAxiosError(err)) {
      return rejectWithValue(err?.response?.data.errors);
    }
  }
});

export const postCheckInviteCodeIsValid = createAsyncThunk(
  'user/checkInviteCodeValid',
  async ({ inviteCode, email }: { inviteCode?: string; email?: string }, { rejectWithValue }) => {
    try {
      const response = await axiosPublic.post(`setup/user/activation/valid`, {
        email,
        inviteCode
      });

      return response.data;
    } catch (error: any | AxiosError) {
      const err = error as AxiosError | Error;
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err?.response?.data.errors);
      }
    }
  }
);

export const postSetupPasswordOnInvite = createAsyncThunk(
  'user/postSetupPassword',
  async (
    { inviteCode, email, password }: { inviteCode?: string; email?: string; password?: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosPublic.post(`setup/user/activation`, {
        email,
        inviteCode,
        password
      });

      return response.data;
    } catch (error: any | AxiosError) {
      const err = error as AxiosError | Error;
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err?.response?.data.errors);
      }
    }
  }
);

export const postVerifyMFA = createAsyncThunk(
  'user/postVerifyMFA',
  async ({ username, userCode }: { username?: string; userCode?: string }, { rejectWithValue }) => {
    try {
      const response = await axiosPublic.post(`setup/auth/verify-mfa`, {
        username,
        userCode
      });

      return response.data;
    } catch (error: any | AxiosError) {
      const err = error as AxiosError | Error;
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err?.response?.data.errors);
      }
    }
  }
);

export const postLoginMFA = createAsyncThunk(
  'user/postLoginMFA',
  async (
    { username, userCode, session }: { username?: string; session?: string; userCode?: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosPublic.post(`setup/auth/user-mfa-code`, {
        username,
        session,
        userCode
      });

      return response.data;
    } catch (error: any | AxiosError) {
      const err = error as AxiosError | Error;
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err?.response?.data.errors);
      }
    }
  }
);

export const postResetPasswordIsValid = createAsyncThunk(
  'user/postResetPasswordIsValid',
  async ({ code, email }: { code?: string; email?: string }, { rejectWithValue }) => {
    try {
      const response = await axiosPublic.post(`setup/reset-password/change/request-valid`, {
        email,
        code
      });

      return response.data;
    } catch (error: any | AxiosError) {
      const err = error as AxiosError | Error;
      if (axios.isAxiosError(err)) {
        return rejectWithValue(err?.response?.data.errors);
      }
    }
  }
);

export const postAuth0Profile = createAsyncThunk(
  'setup/auth0/postAuth0Profile',
  async ({ email }: { email: string }, { rejectWithValue }) => {
    try {
      const response: any = await axiosPublic.get(
        `setup/auth/profile?email=${encodeURIComponent(email)}`
      );

      return response.data;
    } catch (error) {
      const err = error as AxiosError | Error;
      if (axios.isAxiosError(err)) {
        //EXTEND ERR MESSAGE TO
        //HANDLE 429 TOO MANY ATTEMPTS
        const status = err?.response?.status;
        const errObject = err?.response?.data.errors;
        errObject['status'] = status;
        return rejectWithValue(errObject);
      }
    }
  }
);

export const postAuth0Permissions = createAsyncThunk(
  'setup/auth0/postAuth0Permissions',
  async (_, { rejectWithValue }) => {
    try {
      const response: any = await axiosPrivate.get(`setup/auth/permissions`);

      return response.data;
    } catch (error) {
      const err = error as AxiosError | Error;
      if (axios.isAxiosError(err)) {
        //EXTEND ERR MESSAGE TO
        //HANDLE 429 TOO MANY ATTEMPTS
        const status = err?.response?.status;
        const errObject = err?.response?.data.errors;
        errObject['status'] = status;
        return rejectWithValue(errObject);
      }
    }
  }
);
export const getFeaturesEnrollmentPlanner: any = createAsyncThunk(
  'user/getFeaturesEnrollmentPlanner',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`features/enrollment-planner`);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
