import { FC, useState } from 'react';
import {
  Table as TableMaterial,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Pagination
} from '@mui/material';
import clsx from 'clsx';

import { LoadingSpinner } from '../loadingSpinner';
import style from './table.module.scss';

export interface HeadCell {
  id: string;
  label: string | JSX.Element;
  sort?: boolean;
  isMustHide?: boolean;
}

export enum SortValues {
  ASC = 'asc',
  DESC = 'desc'
}

interface Props {
  isLoading: boolean;
  columns: any;
  headCells: HeadCell[];
  totalNumber: number;
  page: number;
  size: number;
  getTableData?: any;
  multisort?: boolean;
  testIdAttr?: string;
}

const Table: FC<Props> = ({
  columns,
  isLoading,
  headCells,
  totalNumber,
  multisort,
  page,
  size,
  getTableData,
  testIdAttr
}) => {
  const [sortConfig, setSortConfig] = useState(
    headCells
      .map<any>((item) => {
        if (item.sort) {
          return {
            title: item.id,
            direction: null
          };
        }
      })
      .filter((notUndefined) => notUndefined !== undefined)
  );

  const handleChangePage = (event: unknown, newPage: number) => {
    getTableData?.({
      page: newPage - 1,
      size: size,
      sorts: sortConfig
    });
  };

  const sort = (itemSort: any) => {
    if (!itemSort) return false;

    const data = sortConfig.map((item) => {
      if (item.title === itemSort) {
        let directionData: string | null = SortValues.ASC;
        if (item.direction === SortValues.ASC) {
          directionData = SortValues.DESC;
        }
        if (item.direction === SortValues.DESC) {
          directionData = null;
        }
        return {
          ...item,
          direction: directionData
        };
      }

      if (multisort) return item;
      return {
        ...item,
        direction: null
      };
    });
    setSortConfig(data);
    getTableData?.({
      page: page,
      size: size,
      sorts: data
    });
  };

  return (
    <div className={style.tableWrap} data-test-id={testIdAttr}>
      {columns && columns.length > 0 && (
        <TableContainer>
          <TableMaterial>
            <TableHead>
              <TableRow>
                {headCells.map((item) => (
                  <TableCell
                    className={clsx(item.sort && style.pointer, item.isMustHide && style.hide)}
                    onClick={() => item.sort && sort(item.id)}
                    key={item.id}
                  >
                    <span className={style.thWrapper}>
                      {item.label}
                      {item.sort && (
                        <span className={style.iconWrapper}>
                          {sortConfig.find((sortItem) => sortItem.title === item.id).direction !==
                            SortValues.DESC && (
                            <span className={clsx('material-icons', style.icon)}>expand_more</span>
                          )}
                          {sortConfig.find((sortItem) => sortItem.title === item.id).direction !==
                            SortValues.ASC && (
                            <span className={clsx('material-icons', style.icon, style.activeIcon)}>
                              expand_more
                            </span>
                          )}
                        </span>
                      )}
                    </span>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>{columns.map((item: any) => item)}</TableBody>
          </TableMaterial>
        </TableContainer>
      )}
      {isLoading && (!columns.length || !columns) && (
        <div className={style.spinner}>
          <LoadingSpinner />
        </div>
      )}
      {columns && columns.length > 0 && Math.ceil(totalNumber / size) > 1 && (
        <div className={style.paginationWrap} data-test-id="table-pagination">
          <Pagination
            className={style.paginationNumbers}
            count={Math.ceil(totalNumber / size)}
            page={page + 1}
            onChange={handleChangePage}
            shape="rounded"
          />
        </div>
      )}
    </div>
  );
};

export default Table;
