import clsx from 'clsx';
import React, { ChangeEvent } from 'react';

import style from './toggleCheckbox.module.scss';

interface ToggleCheckboxProps {
  ref?: string;
  key?: string;
  id?: string;
  name?: string;
  label?: string | null;
  value: boolean;
  onBlur?: (event: ChangeEvent<HTMLInputElement>) => void;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (event: ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  size?: 'small';
}

//TO get the value in custom function, use following approach ->
//
// const functionHandlingCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
//   console.log('Checkbox returns it's state (true/false): ', event.target.checked);
// };

const ToggleCheckbox: React.FunctionComponent<ToggleCheckboxProps> = ({
  ref,
  key,
  id,
  name,
  label,
  value,
  onBlur,
  onChange,
  onFocus,
  disabled,
  size
}) => {
  return (
    <div
      className={clsx(
        style.inputContainer,
        disabled && style.disabled,
        size === 'small' && style.inputContainerSmall
      )}
    >
      <label className={style.switch}>
        <input
          ref={ref}
          key={key}
          id={id}
          checked={value}
          name={name}
          type={'checkbox'}
          onChange={onChange}
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
        />
        <i></i>
        <div className={style.checkBoxLabel}>{label}</div>
      </label>
    </div>
  );
};

export default ToggleCheckbox;
