import { ClickAwayListener } from '@mui/material';
import clsx from 'clsx';
import { InputField, StandardCheckbox } from 'components';
import dayjs from 'dayjs';
import { useFormik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { useAppDispatch } from 'store/hooks';
import { createTodo, updateTodo } from 'store/roadmap/roadmapActions';
import {
  GoalsDTO,
  RoadmapStatus,
  TodosDTO,
  deleteTodoOrGoal,
  revertUpdateTodoOrGoal,
  updateTodoOrGoal
} from 'store/roadmap/roadmapSlice';
import * as Yup from 'yup';

import { GoalForm } from './goalForm';
import style from './roadmaps.module.scss';
import { TodoEventForm } from './todoEventForm';
import TodoPopper from './todoPopper/todoPopper';

interface Props {
  providedChildren?: DraggableProvided;
  data: TodosDTO;
  dataParent?: GoalsDTO;
  selectedDraggableId?: string | null;
  isTodo?: boolean;
  clientId: string;
}

const getDate = (
  startDateTime: string | undefined | null,
  endDateTime: string | undefined | null,
  date: string | undefined | null
) => {
  const hasUTCDate = Boolean(startDateTime) && Boolean(endDateTime);
  const hasSameDates = dayjs(startDateTime).isSame(endDateTime, 'date');

  if (hasUTCDate) {
    const startDate = dayjs(startDateTime).format('MMM D');
    const endDate = dayjs(endDateTime).format('MMM D');
    const startTime = dayjs(startDateTime).format('h:mm a');
    const endTime = dayjs(endDateTime).format('h:mm a');

    if (!hasSameDates) {
      return `(${startDate} - ${startTime} | ${endDate} - ${endTime})`;
    }

    return `(${startDate} | ${startTime} - ${endTime})`;
  }

  if (date) {
    return `(${dayjs(date).format('MMM D')})`;
  }

  return null;
};

const ToDo: FC<Props> = ({
  providedChildren,
  data,
  selectedDraggableId,
  isTodo,
  dataParent,
  clientId
}) => {
  const dispatch = useAppDispatch();
  const [isTyping, setIsTyping] = useState<boolean>(false);

  const formattedDate = getDate(data.startDateTime, data.endDateTime, data.date);

  const validationSchema = Yup.object().shape({
    title: Yup.string().max(60).required()
  });

  const { handleChange, handleBlur, handleSubmit, values } = useFormik({
    initialValues: data,
    enableReinitialize: true,
    validationSchema,
    onSubmit: (newData) => {
      const newDataVal = { ...newData, goalId: newData.goalId || dataParent?.id };
      if (data.id) {
        dispatch(updateTodo({ data: newDataVal, clientId, dataParent }));
      } else {
        dispatch(createTodo({ data: newDataVal, clientId, dataParent }));
      }
      setIsTyping(false);
    }
  });

  useEffect(() => {
    if (!data.id && data.title.length === 0) {
      setIsTyping(true);
    }
  }, [data]);

  return (
    <ClickAwayListener
      onClickAway={() =>
        isTyping &&
        (!data.id
          ? dispatch(deleteTodoOrGoal({ ...data, isTodo, dataParent }))
          : dispatch(revertUpdateTodoOrGoal())) &&
        data.status === RoadmapStatus.ACTIVE &&
        setIsTyping(false)
      }
    >
      <div
        ref={providedChildren?.innerRef}
        {...providedChildren?.draggableProps}
        className={clsx(
          style.item,
          isTyping && style.typing,
          data.status === RoadmapStatus.ABANDONED && style.abandoned,
          data.status === RoadmapStatus.COMPLETED && style.completed,
          data.orderNum && data.orderNum.toString() === selectedDraggableId && style.selected,
          selectedDraggableId && style.disableHover
        )}
      >
        <div
          {...providedChildren?.dragHandleProps}
          className={clsx('material-icons-outlined', style.dragIndicator)}
        >
          drag_indicator
        </div>
        <div className={style.content}>
          <div className={style.titleWrap}>
            <StandardCheckbox
              disabled={isTyping || data.status === RoadmapStatus.ABANDONED}
              name="status"
              onChange={() => {
                const statusNew =
                  data.status === RoadmapStatus.COMPLETED
                    ? RoadmapStatus.ACTIVE
                    : RoadmapStatus.COMPLETED;
                dispatch(
                  updateTodo({ data: { ...data, status: statusNew }, clientId, dataParent })
                ).then((resolve: any) => {
                  if (!resolve.error) {
                    dispatch(
                      updateTodoOrGoal({ data: { ...data, status: statusNew }, isTodo, dataParent })
                    );
                  }
                });
              }}
              value={data.status === RoadmapStatus.COMPLETED || false}
              customClass={style.checkbox}
            />
            {isTyping ? (
              <form
                onSubmit={handleSubmit}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    handleSubmit();
                  }
                }}
              >
                <InputField
                  id="title"
                  name="title"
                  type="text"
                  placeholder="Type and hit ENTER to save"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.title}
                  classnamesProps={style.typingInput}
                  maxLength={60}
                  autoFocus
                />
              </form>
            ) : (
              <div
                className={style.title}
                onDoubleClick={() => {
                  if (data.status === RoadmapStatus.ACTIVE) {
                    setIsTyping(true);
                  }
                }}
              >
                {data.title} {formattedDate && <span>{formattedDate}</span>}{' '}
                {data.location ? `| ${data?.location}` : ''}
              </div>
            )}
          </div>
          <div className={style.icons}>
            {data.status === RoadmapStatus.ACTIVE && (
              <>
                <TodoPopper icon="event">
                  {({ onClose }) => (
                    <TodoEventForm todo={data} onClose={onClose} dataParent={dataParent} />
                  )}
                </TodoPopper>
                <TodoPopper icon="landscape">
                  {({ onClose }) => (
                    <GoalForm
                      clientId={clientId}
                      todo={data}
                      onClose={onClose}
                      isTodo={isTodo}
                      dataParent={dataParent}
                    />
                  )}
                </TodoPopper>
                <div
                  className="material-icons"
                  onClick={() => {
                    dispatch(
                      updateTodo({
                        data: { ...data, status: RoadmapStatus.ABANDONED },
                        clientId,
                        dataParent
                      })
                    ).then((resolve: any) => {
                      if (!resolve.error) {
                        dispatch(
                          updateTodoOrGoal({
                            data: { ...data, status: RoadmapStatus.ABANDONED },
                            isTodo,
                            dataParent
                          })
                        );
                      }
                    });
                  }}
                >
                  delete
                </div>
              </>
            )}
            {data.status === RoadmapStatus.ABANDONED && (
              <div
                className="material-icons-outlined"
                onClick={() => {
                  dispatch(
                    updateTodo({
                      data: { ...data, status: RoadmapStatus.ACTIVE },
                      clientId,
                      dataParent
                    })
                  ).then((resolve: any) => {
                    if (!resolve.error) {
                      dispatch(
                        updateTodoOrGoal({
                          data: { ...data, status: RoadmapStatus.ACTIVE },
                          isTodo,
                          dataParent
                        })
                      );
                    }
                  });
                }}
              >
                replay_circle_filled
              </div>
            )}
          </div>
        </div>
      </div>
    </ClickAwayListener>
  );
};

export default ToDo;
