import { Auth0ContextInterface, useAuth0, User } from '@auth0/auth0-react';
import {
  getRefreshTokenFromLSAuth0,
  setAutoLogoutRedirectUrl
} from 'modules/networkTools/localStorageTokens';
import { logout } from 'modules/networkTools/networkTools';
import { useIdleTimer } from 'react-idle-timer';
import { useSearchParams } from 'react-router-dom';

export const useAutoLogout = <T extends () => void>(idleTime: number) => {
  const { logout: auth0logout }: Auth0ContextInterface<User> = useAuth0();
  const [searchParams] = useSearchParams();

  const context = searchParams.get('context');
  const handleLogout = () => {
    console.log('Logout timer expired');
    const lsRefreshToken = getRefreshTokenFromLSAuth0('auth0spajs');

    if (context === 'popup') {
      window.close();
      return;
    }

    if (lsRefreshToken) {
      console.log('User got logged out due to inactivity');
      setAutoLogoutRedirectUrl(window.location.pathname);
      auth0logout({
        logoutParams: {
          returnTo: window.location.origin + '/'
        }
      });
      logout();
    }
  };

  useIdleTimer({
    timeout: idleTime * 60_000,
    onIdle: handleLogout,
    debounce: 0,
    crossTab: true,
    events: [
      'mousemove',
      'keydown',
      'wheel',
      'DOMMouseScroll',
      'mousewheel',
      'mousedown',
      'touchstart',
      'touchmove',
      'MSPointerDown',
      'MSPointerMove',
      'visibilitychange',
      'focus'
    ]
  });
};
