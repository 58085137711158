import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';
import { axiosPrivate } from 'modules/networkTools/networkTools';
import { EnrollmentCalendarRequest, EnrollmentCalendarResponse } from 'types/enrollment';
import { convertLocalToUTC } from 'utils/dateHelpers/dateHelpers';

import { EnrollmentCalendarSideModalDataProps } from './enrollmentSlice';

interface EnrollmentProgramDTO {
  programId: number;
  startDate: string;
  enrollmentGoal: number;
}

export const getEnrollmentPrograms: any = createAsyncThunk(
  'enrollment-planner/getEnrollmentPrograms',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`enrollment-planner/settings/organization/programs`);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getEnrollmentNotConfigured: any = createAsyncThunk(
  'enrollment-planner/getEnrollmentNotConfigured',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`enrollment-planner/settings/organization`);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const putEnrollmentNotConfigured: any = createAsyncThunk(
  'enrollment-planner/putEnrollmentNotConfigured',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.put(`enrollment-planner/settings/organization`, data);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getEnrollmentProgram: any = createAsyncThunk(
  'enrollment-planner/getEnrollmentProgram',
  async ({ programId }: { programId: number }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(
        `enrollment-planner/settings/organization/program/${programId}`
      );
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const putEnrollmentProgram: any = createAsyncThunk(
  'enrollment-planner/putEnrollmentProgram',
  async (
    {
      data,
      programId
    }: {
      data: EnrollmentProgramDTO;
      isStep?: boolean;
      programId: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosPrivate.put(
        `enrollment-planner/settings/organization/program/${programId}`,
        data
      );
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getTrackerBar: any = createAsyncThunk(
  'enrollment-planner/getTrackerBar',
  async ({ programId }: { programId?: number }, { rejectWithValue }) => {
    const program = programId ? `?programId=${programId}` : '';
    try {
      const response = await axiosPrivate.get(`enrollment-planner/tracker-bar${program}`);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getEnrollmentGoalsTemplate = createAsyncThunk(
  'enrollment-planner/getEnrollmentGoalsTemplate',
  async (_, { rejectWithValue }) => {
    const url = `enrollment-planner/settings/planning-tool`;
    try {
      const response = await axiosPrivate.get(url, { responseType: 'blob' });
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getEnrollmentUsersList: any = createAsyncThunk(
  'enrollment-planner/getEnrollmentUsersList',
  async ({ programId }: { programId?: number }, { rejectWithValue }) => {
    const program = programId ? `?programId=${programId}` : '';
    try {
      const response = await axiosPrivate.get(`enrollment-planner/chws${program}`);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getUsersImageGoals = createAsyncThunk(
  'enrollment-planner/getUsersImageGoals',
  async (
    { profileImageKey, userId }: { profileImageKey: string; userId?: number },
    { rejectWithValue }
  ) => {
    const url = `setup/organization/user/profile-image?key=${profileImageKey}`;
    try {
      const response = await axiosPrivate.get(url, { responseType: 'blob' });
      return {
        data: response.data,
        userId: userId
      };
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const putEnrollmentUsersList: any = createAsyncThunk(
  'enrollment-planner/putEnrollmentUsersList',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.put(`enrollment-planner/chws/order`, data);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getEnrollmentCalendar = createAsyncThunk<
  EnrollmentCalendarResponse[],
  EnrollmentCalendarRequest,
  Record<string, never>
>(
  'enrollment-planner/getEnrollmentCalendar',
  async ({ programId, start, end }, { rejectWithValue }) => {
    const searchParams = new URLSearchParams({
      start: convertLocalToUTC(start, '00:00'),
      end: convertLocalToUTC(end, '23:59')
    });

    if (programId) {
      searchParams.append('programId', programId.toString());
    }

    try {
      const response = await axiosPrivate.get(`enrollment-planner/calendar?${searchParams}`);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const createEnrollmentCalendar: any = createAsyncThunk(
  'enrollment-planner/createEnrollmentCalendar',
  async (data: EnrollmentCalendarSideModalDataProps, { rejectWithValue }) => {
    const { programId } = data;
    const startDateTime = convertLocalToUTC(data.startDate, data.startTime);
    const endDateTime = convertLocalToUTC(
      data.endDate ? data.endDate : data.startDate,
      data.endTime
    );
    try {
      const response = await axiosPrivate.post(
        `enrollment-planner/program/${programId}/event/create`,
        { ...data, startDateTime, endDateTime }
      );
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateEnrollmentCalendar = createAsyncThunk(
  'enrollment-planner/updateEnrollmentCalendar',
  async (data: EnrollmentCalendarSideModalDataProps, { rejectWithValue }) => {
    const { programId } = data;
    const startDateTime = convertLocalToUTC(data.startDate, data.startTime);
    const endDateTime = convertLocalToUTC(
      data.endDate ? data.endDate : data.startDate,
      data.endTime
    );
    try {
      const response = await axiosPrivate.post(
        `enrollment-planner/program/${programId}/event/update`,
        { ...data, newStartDateTime: startDateTime, newEndDateTime: endDateTime }
      );
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const deleteEnrollmentCalendar: any = createAsyncThunk(
  'enrollment-planner/deleteEnrollmentCalendar',
  async (data: EnrollmentCalendarSideModalDataProps, { rejectWithValue }) => {
    const { programId } = data;
    const startDateTime = convertLocalToUTC(data.startDate, data.startTime);
    const endDateTime = convertLocalToUTC(
      data.endDate ? data.endDate : data.startDate,
      data.endTime
    );
    try {
      const response = await axiosPrivate.post(
        `enrollment-planner/program/${programId}/event/delete`,
        { ...data, startDateTime, endDateTime }
      );
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const searchEnrollmentCHW: any = createAsyncThunk(
  'enrollment-planner/searEnrollmentCHW',
  async (
    { programId, searchTerm }: { programId: number; searchTerm: string },
    { rejectWithValue }
  ) => {
    const searchParams = new URLSearchParams({
      searchTerm
    });

    if (programId) {
      searchParams.append('programIds', programId.toString());
    }

    try {
      const response = await axiosPrivate.get(`enrollment-planner/chws/search?${searchParams}`);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const searchEnrollmentCHWEvents = createAsyncThunk(
  'enrollment-planner/searchEnrollmentCHWEvents',
  async (
    {
      programId,
      userId,
      offset
    }: { programId?: number; userId: number; offset: number; direction?: 'previous' | 'next' },
    { rejectWithValue }
  ) => {
    const searchParams = new URLSearchParams({
      userId: userId.toString(),
      offset: offset.toString(),
      limit: '10'
    });

    if (programId) {
      searchParams.set('programId', programId.toString());
    }

    try {
      const response = await axiosPrivate.get(`enrollment-planner/calendar/search?${searchParams}`);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getEnrollmentProgramsByChw = createAsyncThunk(
  'enrollment-planner/getEnrollmentProgramsByChw',
  async ({ chwId }: { chwId?: string | null }, { rejectWithValue }) => {
    const searchParams = new URLSearchParams();

    if (chwId) {
      searchParams.set('chwIds', chwId);
    }

    try {
      const response = await axiosPrivate.get(
        `enrollment-planner/settings/organization/programs?${searchParams}`
      );
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
export const getEnrollmentChws = createAsyncThunk(
  'enrollment-planner/getEnrollmentChws',
  async ({ programId }: { programId?: string | null }, { rejectWithValue }) => {
    const searchParams = new URLSearchParams({
      size: '100'
    });

    if (programId) {
      searchParams.set('programId', programId);
    }

    try {
      const response = await axiosPrivate.get(`enrollment-planner/chws/active?${searchParams}`);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
