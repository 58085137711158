import { createSlice } from '@reduxjs/toolkit';

import { getScreenerDetails, getScreenerQuestions, getScreeners } from './screenerActions';

export type ScreenerType = 'impact' | 'other';

type Screener = {
  id: number;
  type: ScreenerType;
};

export type Answers = {
  // This key will be the Section[id] which will contain question answers
  [key: string]: {
    // This key will be the Question[id] and the value is answer or answers
    [key: string]: {
      answer: string | string[]; // this field is a string or an array of strings depending if the question is multi answer
      note: string;
    };
  };
};

type ScreenerDetails = {
  updatedAt: string;
  id: string;
  submitted: boolean;
  answers: Answers;
};

type Question = {
  id: string;
  title: string;
  multiselect: boolean;
  options: string[];
};

type Section = {
  title: string;
  id: string;
  questions: Question[];
};

type ScreenerHistory = {
  submittedOn: string;
  id: string;
  name: string;
  imageUrl: string;
};

type OtherScreenerDetails = {
  id: string;
  updatedAt: string;
  dateOfScreening: string;
  needsIdentified: string[];
  notes: string;
  submitted: boolean;
};

type InitialState = {
  [key: string]: {
    screeners: Screener[];
    questionnare: {
      [key in Exclude<ScreenerType, 'other'>]: Section[];
    };
    screener: {
      [key in Exclude<ScreenerType, 'other'>]: ScreenerDetails | null;
    };
    screenerHistory: {
      [key in Exclude<ScreenerType, 'other'>]: ScreenerHistory[];
    };
    otherScreenerHistory: ScreenerHistory[];
    otherScreener: OtherScreenerDetails;
  };
};

const initialState: InitialState = {
  '533': {
    screeners: [
      {
        id: 1,
        type: 'impact'
      },
      { id: 2, type: 'other' }
    ],
    questionnare: {
      impact: [
        {
          id: '31e0ddbb-3e1b-45f4-af29-4d10e2b514e2',
          title: 'Mental Health',
          questions: [
            {
              id: '764b7b35-4e53-4c17-a081-60c494d91f43',
              multiselect: false,
              title:
                "Let's talk about your mood. Do you feel like you might be depressed or anxious?",
              options: ['Yes', 'No']
            }
          ]
        },
        {
          id: 'bdf58337-3f04-4f91-bfc3-bd7f2a7aa2d0',
          title: 'Substance Use',
          questions: [
            {
              id: '01a0cf5c-454e-4aa5-bf92-057e978d2de4',
              multiselect: true,
              title:
                'Can you tell me a little about your habits? Are you someone who smokes cigarettes? Drink alcohol or beer regularly? Any recreational drugs? Select all that apply.',
              options: ['Smoke', 'Drink', 'Drugs']
            }
          ]
        },
        {
          id: '9fba3e19-2d1d-46ed-8832-6fd46c44b8f4',
          title: 'Social Support',
          questions: [
            {
              id: '96d29df3-50fb-431a-83bc-5da3c8a7e1bf',
              multiselect: false,
              title:
                "These days lots of people are feeling lonely. Would you say that you're lonely?",
              options: ['Yes', 'No']
            }
          ]
        },
        {
          id: '7b5973c3-41e7-43d2-a703-10fc3ed9a66f',
          title: 'Intimate Partner Safety',
          questions: [
            {
              id: '3e038a04-8f0a-43f3-8b0c-0a5e0d3e05c4',
              multiselect: false,
              title:
                'Have you ever had a relationship with someone who made you feel unsafe or hurt you in any way?',
              options: ['Yes', 'No']
            },
            {
              id: 'e2d2c0fc-7f4d-4c1f-b43d-0b3957f331d7',
              multiselect: false,
              title: 'Are you currently in a relationship like this?',
              options: ['Yes', 'No']
            }
          ]
        },
        {
          id: '9f57d164-0c94-42f3-a0aa-b03f8d768a4b',
          title: 'Financial Stability',
          questions: [
            {
              id: '8860ac4e-72ff-4889-a07b-cb0d51882822',
              multiselect: false,
              title:
                'A lot of people I talk to are having trouble paying for things like food, housing, utilities, transportation, or childcare. Is that a concern for you?',
              options: ['Yes', 'No']
            },
            {
              id: 'd863d16f-17a1-4cd3-8c5b-7bfe3735dc9b',
              multiselect: true,
              title:
                'If Yes to above: What are some things you have trouble paying for? Select all that apply.',
              options: [
                'Childcare',
                'Clothing & Goods',
                'Food',
                'Housing',
                'Phone & Internet',
                'Transportation',
                'Utilities'
              ]
            }
          ]
        },
        {
          id: '97348f7c-cf82-4f07-ba27-840a5f52906b',
          title: 'Employment',
          questions: [
            {
              id: 'fa4adfcf-d55f-44c1-b34d-f78015419d6a',
              multiselect: false,
              title: 'Do you need help finding a job?',
              options: ['Yes', 'No']
            }
          ]
        },
        {
          id: '66b065e4-84a7-47b3-afeb-58eb68b3014d',
          title: 'Benefits & Insurance',
          questions: [
            {
              id: 'f5a5123f-87bc-4c44-af5d-0973cb504a71',
              multiselect: false,
              title:
                'Do you need help with benefits like social security, health insurance or food assistance?',
              options: ['Yes', 'No']
            }
          ]
        },
        {
          id: 'c04a5ef2-04f4-46cd-a81a-1413e93e0db5',
          title: 'Legal',
          questions: [
            {
              id: '0205e5d6-8887-4b76-9954-86b0c4148e28',
              multiselect: false,
              title:
                'Are you dealing with any legal issues including tenant’s rights, immigration or child services?',
              options: ['Yes', 'No']
            }
          ]
        },
        {
          id: 'f9d94a2b-f40d-44af-bf57-8a8f635a57d0',
          title: 'Incarceration',
          questions: [
            {
              id: 'f429a542-05a4-4f3b-b774-45b470a789d2',
              multiselect: false,
              title:
                'In the past year, have you spent more than 2 nights in a row in a jail, prison, detention center, or juvenile correctional facility?',
              options: ['Yes', 'No']
            }
          ]
        },
        {
          id: 'b2db3179-29ed-422e-8e99-8e4e95a2cd0e',
          title: 'Empowerment',
          questions: [
            {
              id: '48b0aee7-8e2a-43e8-b2cc-8b69d0e14a2f',
              multiselect: false,
              title:
                'Many people I talk to have been judged or discriminated against. Is this happening to you?',
              options: ['Yes', 'No']
            },
            {
              id: '83bc7b40-48d0-4ed9-8c1f-977ef930f8a4',
              multiselect: false,
              title:
                'Is your neighborhood having trouble getting the resources it needs, like trash pickup, public safety, etc.?',
              options: ['Yes', 'No']
            },
            {
              id: '99de44b0-0a41-4584-a250-e9b21c364a6f',
              multiselect: false,
              title: 'Do you feel like you have a voice in the political system?',
              options: ['Yes', 'No']
            }
          ]
        },
        {
          id: '32d0d944-e71c-4d3a-8f39-1a7088c6cf43',
          title: 'Physical Health',
          questions: [
            {
              id: '1fa8e23c-9e5e-491f-8805-43dd3fb99fc9',
              multiselect: false,
              title:
                'Let’s talk about your health. How is your health right now on a scale of 1-5, 5 being the best?',
              options: ['1', '2', '3', '4', '5']
            }
          ]
        },
        {
          id: '98c32b6d-52d2-4539-baf4-7980fd19668c',
          title: 'Health habits',
          questions: [
            {
              id: '5110d3f4-0400-4f41-b080-f05b3a8b4e2c',
              multiselect: false,
              title:
                'Here are the medical conditions I have listed for you. Are there any in particular that you want to focus on getting under control over the next (X weeks/months)?',
              options: ['Yes', 'No']
            },
            {
              id: '6f4cf88b-3420-4810-a5e5-305b214a88a5',
              multiselect: false,
              title: 'Sleep can really affect our health. Do you have trouble with sleep?',
              options: ['Yes', 'No']
            },
            {
              id: 'a2cc26ac-3fc2-4b42-b2fd-b05d1461658c',
              multiselect: false,
              title: 'Activities of Daily Living',
              options: ['Yes', 'No']
            }
          ]
        },
        {
          id: '54f6cf88-b82c-42eb-bc15-44b6b8e7a53f',
          title: 'Access to healthcare',
          questions: [
            {
              id: 'b25b6b36-9c6b-43b6-bfe5-b8199b4a206e',
              multiselect: false,
              title: 'Do you feel like you have the right primary care doctor?',
              options: ['Yes', 'No']
            },
            {
              id: 'd36898f6-84a7-4e4f-9912-3e9c740f3762',
              multiselect: false,
              title:
                'Anything that makes it hard for you to get what you need from your doctor, like trouble getting appointments, wait times, rude staff, or copays?',
              options: ['Yes', 'No']
            },
            {
              id: '485f18d2-1b1a-4c79-bb85-c2fd2bbd81e8',
              multiselect: false,
              title: 'How many times in the last year have you been to the emergency room?',
              options: ['0', '1', '2', '3', '4', '5', '6+']
            },
            {
              id: 'b650992d-35c2-45e0-bf23-5dd6c15c0903',
              multiselect: false,
              title: 'Does anything make it hard for you to take your medications every day?',
              options: ['Yes', 'No']
            },
            {
              id: '6c23d15c-c02b-4636-a14d-fb7a7dcb72a1',
              multiselect: false,
              title:
                'Do you have any trouble with getting the medical supplies and equipment you need? Like glucometers, blood pressure cuffs, canes, walkers?',
              options: ['Yes', 'No']
            }
          ]
        }
      ]
    },
    screener: {
      impact: null
    },
    screenerHistory: {
      impact: [
        {
          id: '3bdc20e4-09c2-48cb-9e79-c50c95c65620',
          submittedOn: '2024-02-20T17:00:00.000Z',
          name: 'John Doe',
          imageUrl: 'f3b28867-bac0-44e9-84f3-cc3f2a2efaea.jpg'
        },
        {
          id: '3bdc20e4-09c2-48cb-9e79-c50c95c5151',
          submittedOn: '2024-02-20T17:00:00.000Z',
          name: 'John Doe',
          imageUrl: 'f3b28867-bac0-44e9-84f3-cc3f2a2efaea.jpg'
        },
        {
          id: '3bdc20e4-09c2-48cb-9e79-c50c95c1411',
          submittedOn: '2024-02-20T17:00:00.000Z',
          name: 'John Doe',
          imageUrl: 'f3b28867-bac0-44e9-84f3-cc3f2a2efaea.jpg'
        }
      ]
    },
    otherScreenerHistory: [
      {
        id: '3bdc20e4-09c2-48cb-9e79-c50c95c65620',
        submittedOn: '2024-02-20T17:00:00.000Z',
        name: 'John Doe',
        imageUrl: 'f3b28867-bac0-44e9-84f3-cc3f2a2efaea.jpg'
      },
      {
        id: '3bdc20e4-09c2-48cb-9e79-c50c95c5151',
        submittedOn: '2024-02-20T17:00:00.000Z',
        name: 'John Doe',
        imageUrl: 'f3b28867-bac0-44e9-84f3-cc3f2a2efaea.jpg'
      },
      {
        id: '3bdc20e4-09c2-48cb-9e79-c50c95c1411',
        submittedOn: '2024-02-20T17:00:00.000Z',
        name: 'John Doe',
        imageUrl: 'f3b28867-bac0-44e9-84f3-cc3f2a2efaea.jpg'
      }
    ],
    otherScreener: {
      id: '3bdc20e4-09c2-48cb-9e79-c50c95c65620',
      updatedAt: '2024-02-20T17:00:00.000Z',
      submitted: false,
      dateOfScreening: '2024-02-20',
      needsIdentified: ['FOOD', 'UTILITIES'],
      notes: 'Test note'
    }
  }
};

export const screenerSlice = createSlice({
  name: 'screenerSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getScreeners.fulfilled, (state, action) => {
        state.screeners = action.payload;
      })
      .addCase(getScreeners.rejected, (state, action) => {})
      .addCase(getScreenerQuestions.fulfilled, (state, action) => {
        state[action.meta.arg.id] = {
          ...state[action.meta.arg.id],
          questionnare: {
            [action.meta.arg.type]: action.payload
          }
        };
      })
      .addCase(getScreenerQuestions.rejected, (state, action) => {})
      .addCase(getScreenerDetails.fulfilled, (state, action) => {
        state[action.meta.arg.id] = {
          ...state[action.meta.arg.id],
          screener: {
            [action.meta.arg.type]: action.payload
          }
        };
      })
      .addCase(getScreenerDetails.rejected, (state, action) => {});
  }
});

export default screenerSlice.reducer;
