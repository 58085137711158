import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosError } from 'axios';

import { axiosPrivate } from '../../modules/networkTools/networkTools';

export const getScreeners = createAsyncThunk(
  'clients/getScreneers',
  async ({ id }: { id: string | number }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`clients/${id}/screeners`);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getScreenerQuestions = createAsyncThunk(
  'clients/getScreenerQuestions',
  async ({ id, type }: { id: string | number; type: 'impact' }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`clients/${id}/screener/questions/${type}`);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getScreenerDetails = createAsyncThunk(
  'clients/getScreenerDetails',
  async ({ id, type }: { id: string | number; type: 'impact' }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`clients/${id}/screener/${type}`);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

type Screener = {
  submitted: boolean;
  answers: {
    // This key will be the Section[id] which will contain question answers
    [key: string]: {
      // This key will be the Question[id] and the value is answer or answers
      [key: string]: {
        answer: string | string[];
        note: string;
      };
    };
  };
};

export const postScreener = createAsyncThunk(
  'clients/postScreener',
  async (
    { id, type, screener }: { id: string | number; type: 'impact'; screener: Screener },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosPrivate.post(`clients/${id}/screener/${type}`, screener);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const putScreener = createAsyncThunk(
  'clients/putScreener',
  async (
    { id, type, screener }: { id: string | number; type: string; screener: Screener },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosPrivate.put(`clients/${id}/screener/${type}`, screener);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const patchScreener = createAsyncThunk(
  'clients/patchScreener',
  async (
    { id, type, screener }: { id: string | number; type: string; screener: Screener },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosPrivate.patch(`clients/${id}/screener/${type}`, screener);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getScreenerHistory = createAsyncThunk(
  'clients/getScreenerHistory',
  async ({ id, type }: { id: string | number; type: string }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`clients/${id}/screener/${type}/history`);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getOtherScreenerDetails = createAsyncThunk(
  'clients/getOtherScreenerDetails',
  async ({ id }: { id: string | number }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`clients/${id}/screener/other`);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const postOtherScreener = createAsyncThunk(
  'clients/postOtherScreener',
  async (
    { id, type, screener }: { id: string | number; type: string; screener: Screener },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosPrivate.post(`clients/${id}/screener/${type}`, screener);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const putOtherScreener = createAsyncThunk(
  'clients/putOtherScreener',
  async (
    { id, type, screener }: { id: string | number; type: string; screener: Screener },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosPrivate.put(`clients/${id}/screener/${type}`, screener);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const patchOtherScreener = createAsyncThunk(
  'clients/patchOtherScreener',
  async (
    { id, type, screener }: { id: string | number; type: string; screener: Screener },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosPrivate.patch(`clients/${id}/screener/${type}`, screener);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getOtherScreenerHistory = createAsyncThunk(
  'clients/getOtherScreenerHistory',
  async ({ id }: { id: string | number }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`clients/${id}/screener/other/history`);
      return response.data;
    } catch (error: any | AxiosError) {
      if (error.response && error.response.data.errors) {
        return rejectWithValue(error.response.data.errors);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
