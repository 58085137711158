import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  deleteLsBothTokens,
  deleteLsFeaturesEnrollmentPlanner,
  deleteLsUserRoleObject,
  removeCurrentNote,
  setLsBothTokens,
  setLsFeaturesEnrollmentPlanner,
  setLsUserRoleObject
} from '../../modules/networkTools/localStorageTokens';
import {
  MSG_ACTIVATION_SUCCESS,
  MSG_SEND_FORCE_PASS_REQUEST_ERROR,
  MSG_SEND_FORCE_PASS_REQUEST_SUCCESS,
  MSG_SEND_RESET_CODE_SUCCESS_EMAIL
} from '../../utils/errorMessages';
import { notifyUserError, notifyUserSuccess } from '../../utils/notifications';
import {
  getFeaturesEnrollmentPlanner,
  getForcePasswordResetRequest,
  getResetPasswordCode,
  postChangePasswordUser,
  postLoginMFA,
  postLoginUser,
  postLogoutUser,
  postRegisterUser,
  postResetPasswordIsValid,
  postSetupPasswordOnInvite,
  postVerifyMFA
} from './authActions';

type UserProps = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password: string;
  confirmPassword: string;

  forceReset?: boolean;
  resetPasswordBy: 'email';
  code: string;
};

export interface AuthSliceProps {
  user: UserProps;
  loading: boolean;
  success: boolean;
  error: string | null | undefined;
}

const initialState: AuthSliceProps = {
  user: {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',

    resetPasswordBy: 'email',
    code: ''
  },
  loading: false,
  error: null,
  success: false
};

export const authSlice = createSlice({
  name: 'authSlice',
  initialState,
  reducers: {
    resetStatuses: (state) => {
      state.success = false;
      state.loading = false;
      state.error = null;
    },

    updateUserData: (state, action: PayloadAction<UserProps>) => {
      state.user = { ...state.user, ...action.payload };
    },

    logout: (state) => {
      deleteLsBothTokens();
      deleteLsUserRoleObject();
      deleteLsFeaturesEnrollmentPlanner();
      state.user = { ...state.user, email: '', password: '' };
      state.success = false;
      state.loading = false;
      state.error = null;
    }
  },
  extraReducers: (builder) => {
    builder //getResetPasswordCode
      .addCase(postResetPasswordIsValid.pending, (state) => {})
      .addCase(postResetPasswordIsValid.fulfilled, (state: any, { payload }: any) => {
        // notifyUserSuccess(MSG_SEND_RESET_CODE_SUCCESS_EMAIL);
      })
      .addCase(getResetPasswordCode.pending, (state) => {})
      .addCase(getResetPasswordCode.fulfilled, (state: any, { payload }: any) => {
        notifyUserSuccess(MSG_SEND_RESET_CODE_SUCCESS_EMAIL);
      })
      .addCase(getResetPasswordCode.rejected, (state, { payload }) => {
        notifyUserError(payload);
      })
      .addCase(getForcePasswordResetRequest.fulfilled, (state: any, { payload }: any) => {
        notifyUserSuccess(MSG_SEND_FORCE_PASS_REQUEST_SUCCESS);
      })
      .addCase(getForcePasswordResetRequest.rejected, (state, { payload }) => {
        notifyUserError(MSG_SEND_FORCE_PASS_REQUEST_ERROR);
      }) //post postChangePasswordUser

      .addCase(postChangePasswordUser.fulfilled, (state) => {})
      .addCase(postChangePasswordUser.rejected, (state, { payload }) => {
        state.loading = false;

        notifyUserError(payload);
      }) //post postRegisterUser

      .addCase(postRegisterUser.fulfilled, (state) => {})
      .addCase(postRegisterUser.rejected, (state, { error, payload }) => {
        notifyUserError(payload);
      }) //postLoginUser
      .addCase(postLoginUser.fulfilled, (state, { payload }) => {})
      .addCase(postLoginUser.rejected, (state, { error, payload }: any) => {}) //putLogoutUser
      .addCase(postLogoutUser.fulfilled, (_, { payload }) => {
        // ON  LOGOUT REMOVE ACTUAL NOTE ID FROM LS
        removeCurrentNote();
      })
      .addCase(postLogoutUser.rejected, (_, { payload }) => {
        notifyUserError(payload);
      })
      .addCase(postSetupPasswordOnInvite.fulfilled, (state, { payload }) => {
        notifyUserSuccess(MSG_ACTIVATION_SUCCESS);
      })
      .addCase(postSetupPasswordOnInvite.rejected, (state, { error, payload }: any) => {
        notifyUserError(payload?.[0]?.message);
      })
      //TODO remove old login
      .addCase(postLoginMFA.fulfilled, (_, { payload }) => {
        setLsBothTokens(payload.loginData.accessToken, payload.loginData.refreshToken);
        setLsUserRoleObject(payload.loginData);
      })
      .addCase(postVerifyMFA.rejected, (_, { payload }: any) => {
        if (
          payload[0].message.startsWith('Code mismatch') ||
          payload[0].message.startsWith('Incorrect MFA code')
        ) {
          notifyUserError('Invalid code. Please try again.');
        } else if (
          payload[0].message.startsWith('Your software token has already been used once')
        ) {
          notifyUserError(
            'This code has already been used for the setup. Please wait for the next one.'
          );
        } else {
          notifyUserError('Something went wrong. Please try again.');
        }
      })
      .addCase(getFeaturesEnrollmentPlanner.fulfilled, (_, { payload }) => {
        setLsFeaturesEnrollmentPlanner(payload);
      });
  }
});

export const { updateUserData, logout } = authSlice.actions;

export default authSlice.reducer;
