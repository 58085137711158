import clsx from 'clsx';
import { LinkWithIndirectRouting } from 'components/linkWithConfirm';
import { FC } from 'react';
import { useUserAuthStatusCheckerByPermission } from 'utils/jwtDecoder';

import style from './navbar.module.scss';

export interface NavItem {
  path: string;
  name: string;
  permission: string[];
  icon: string;
  showItem: string;
}

interface Props {
  navItems: NavItem[];
}

const NavBar: FC<Props> = ({ navItems }) => {
  const { permissions } = useUserAuthStatusCheckerByPermission();
  const filteredNavItems = navItems.filter((x) =>
    x.permission.find((a) => permissions.includes(a) || a === 'all')
  );

  return (
    <div className={style.navbar} data-test-id="navbar">
      {filteredNavItems
        .filter((x) => x.showItem === 'true')
        .map((item, i) => (
          <LinkWithIndirectRouting
            key={i}
            path={item.path}
            styleClass={style.link}
            styleClassActive={style.linkActive}
            content={
              <>
                <span className={clsx('material-icons-outlined', style.menuIcon)}>{item.icon}</span>
                {item.name}
              </>
            }
          />
        ))}
    </div>
  );
};

export default NavBar;
